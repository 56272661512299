import React, {FC, useEffect, useState} from 'react';
import {ConfigProvider, Input, Modal, Progress, Upload} from 'antd';
import styles from './Dictionaries.module.scss';
import {DictionaryItem} from '../../types/DictionaryItem';
import axios from 'axios';
import {getCookie} from '../../utils';
import {URL} from '../../services/requst_templates';

export type DictionaryModalProps = {
    title: string;
    editDictionary?: DictionaryItem | null;
    isModalOpen: boolean;
    onConfirmHandler: (dictionary:DictionaryItem) => void;
    onCancelHandler: () => void;
    onEditHandler?: (e: React.ChangeEvent<HTMLInputElement>) => void;


};


export const DictionaryModal: FC<DictionaryModalProps> = ({
                                                              title,
                                                              editDictionary,
                                                              isModalOpen,
                                                              onCancelHandler,
                                                              onConfirmHandler,
                                                              onEditHandler
                                                          }) => {
    const initialDictionary = {
        id: "",
        name: "",
        description: ""
    }

    const initErrors = {
        name: "",
        description: "",
        file: ""

    }

    useEffect(() => {
        if (editDictionary) {
            setDictionary(editDictionary);
        }
    }, [editDictionary]);


    const [dictionary, setDictionary] = useState<DictionaryItem>(initialDictionary);
    const [progress, setProgress] = useState(0);
    const [submitting, setSubmitting] = useState(false);
    const [errors, setErrors] = useState({
        name: "",
        description: "",
        file: ''

    });

    const [hasErrors, setHasErrors] = useState<boolean>(false);


    const [file, setFile] = useState<any>();


    const onAddFile = (opts: any) => {
        setFile(opts.file);
        setErrors((prev) => ({...prev, file: ""}));
    }


    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDictionary((prev) => ({...prev, [e.target.name]: e.target.value}));
        setErrors((prev) => ({...prev, [e.target.name]: ""}));
    }

    const onSave = () => {
        validateValues(dictionary)
        setSubmitting(true);
    }

    const onCancel = () => {
        clearForm();
        onCancelHandler();
    }

    const clearForm = () => {
        setSubmitting(false);
        setDictionary(initialDictionary);
        setHasErrors(false);
        setErrors(initErrors);
        setFile(null);
    }

    const sizeToMb = (sizeInByte: number): string => {
        let sizeMb = Math.round((sizeInByte / (1024 * 1024) + Number.EPSILON) * 100) / 100
        return (!sizeInByte ? '' : ' TXT・ ' + sizeMb.toFixed(2) + ' Mb');
    }

    const validateValues = (dictionary: DictionaryItem) => {
        let hasErrors = false;

        let errors = {
            name: "",
            description: "",
            file: ""
        };
        if (!dictionary?.name) {
            errors.name = "Необходимо заполнить поле";
            hasErrors = true;

        }
        if (!dictionary?.description) {
            errors.description = "Необходимо заполнить поле";
            hasErrors = true;

        }
        if (!file && !editDictionary?.id) {
            errors.file = "Необходимо добавить файл"
            hasErrors = true;

        }
        setErrors(errors);
        setHasErrors(hasErrors);
        if (hasErrors) setSubmitting(false);
        return errors;
    };


    useEffect(() => {
        console.log('USE_EFFECT')
        if (!hasErrors && submitting) {
            saveDictionary()
        }
    }, [errors]);

    const saveDictionary = () => {


        uploadDictionary(dictionary).then((resp) => {
            console.log(resp);
        });

    }

    const uploadDictionary = async (dictionary: DictionaryItem) => {


        const formData = new FormData();
        formData.append('name', dictionary.name);
        formData.append('description', dictionary?.description || '');
        formData.append("file", file);

        console.log('FORM');
        console.log(formData);

        const config = {
            headers: {
                "content-type": "multipart/form-data",
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${getCookie('token')}`
            },
            onUploadProgress: (event: any) => {
                console.log('UPLOAD_EVENT');
                console.log(event);
                const percent = Math.floor((event.loaded / event.total) * 100);
                setProgress(percent);
                if (percent === 100) {
                    setTimeout(() => setProgress(0), 1000);
                }
            }
        };

        try {
            let res;
            if (dictionary?.id) {

                res = await axios.patch(
                    `${URL}/v1/dictionary/file/${encodeURIComponent(dictionary?.id)}`,
                    formData,
                    config
                );

            } else {
                res = await axios.post(
                    `${URL}/v1/dictionary/file`,
                    formData,
                    config
                );

            }
            setSubmitting(false);
            console.log("server res: ", res);

            if (res.status === 200) {
                clearForm();
                onConfirmHandler(res.data);
            }
        } catch (err) {
            console.log("Eroor: ", err);
            setSubmitting(false);
        }

    }

    return (
        <ConfigProvider
            theme={{
                components: {
                    Button: {
                        colorPrimary: '#F75623',
                        colorPrimaryHover: '#F75623',
                        defaultColor: '#F75623',
                        defaultBorderColor: '#F75623',
                        colorBorder: '1px solid #F75623',
                        colorPrimaryBorder: '1px solid #F75623',
                        algorithm: true
                    },
                    Input: {
                        colorPrimary: '#F75623',
                        activeShadow: '0 0 0 1px #F75623',
                        colorErrorBorder: '#D92020',
                        algorithm: true,
                    },
                    Select: {
                        colorPrimary: '#F75623',
                        colorErrorBorder: '#D92020',
                        colorBorder: '2px solid #F75623',
                        colorPrimaryBorder: '2px solid #F75623',
                        boxShadow: ' 1px #F75623',
                        colorText: '#23262D',
                        colorTextPlaceholder: '#717681',

                        algorithm: true,
                    },
                    Modal: {
                        colorPrimary: '#F75623',
                        colorBorder: '2px solid #F75623',
                        colorPrimaryBorder: '2px solid #F75623',
                        algorithm: true
                    },
                    Switch: {
                        colorPrimary: '#F75623',
                        algorithm: true
                    },

                },
            }}>

            <Modal title={<span className={'header_2 neutral_90'}> {title} </span>}

                   open={isModalOpen}
                   width={560}
                   onOk={onSave}
                   onCancel={onCancel}
                   okButtonProps={{disabled: submitting && !hasErrors}}
                   cancelButtonProps={{disabled: submitting && !hasErrors}}

                   cancelText={'Отменить'}
                   okText={'Сохранить'}
                   getContainer={() => document.getElementById("dictionariesAll")!}>


                <div style={{width: '100%'}}>
                    <div className={'body_1_long neutral_50'}>
                        Название
                        <span className={styles.required}>
                            *
                        </span>
                    </div>
                    <div>

                        <Input name="name"
                               disabled={submitting && !hasErrors}
                               value={dictionary?.name}
                               onChange={(e) => onInputChange(e)}/>
                        {errors.name ? (
                            <p className={styles.error}> {errors.name} </p>
                        ) : null}
                    </div>

                    <div className={'body_1_long neutral_50'}>
                        Описание
                        <span className={styles.required}>
                            *
                        </span>
                    </div>
                    <div>
                        <Input name="description"
                               disabled={submitting && !hasErrors}
                               value={dictionary?.description}
                               onChange={(e) => onInputChange(e)}/>
                        {errors.description ? (
                            <p className={styles.error}> {errors.description} </p>
                        ) : null}
                    </div>

                    <div style={{marginTop: '10px'}} className={'body_1_long neutral_90'}>

                        <Upload.Dragger multiple={false}
                                        disabled={submitting && !hasErrors}
                                        accept='.txt'
                                        maxCount={1}
                                        showUploadList={false}
                                        customRequest={onAddFile}>
                            Перетащите в эту область или <span className={'primary_60'}>загрузите</span>
                            <br/>
                            словарь в формате txt
                        </Upload.Dragger>
                        {errors.file ? (
                            <p className={styles.error}> {errors.file} </p>
                        ) : null}

                        <div className={styles.uploaded_file} hidden={!file?.name}>

                            {file?.name.split('.')[0]}
                            <span className={'body_1_long neutral_50'}>
                                {'' + sizeToMb(file?.size)}
                            </span>

                            {progress > 0 ? <Progress style={{float: 'right', paddingRight: '10px'}}
                                                      type={'circle'}
                                                      size={24}
                                                      strokeColor={'#F75623'}
                                                      percent={progress}/>
                                : null}
                        </div>
                    </div>
                </div>
            </Modal>
        </ConfigProvider>
    )
}
