import * as React from 'react';
import {useEffect, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import {ReactComponent as Logo} from '../../assets/icons/damask_icon_login.svg';
import {ReactComponent as Status} from '../../assets/icons/damask_status_badge.svg';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import styles from './ButtonAppBar.module.scss';
import {doNavigate, getCurrentUser, i18n, signOut} from '../../utils';
import {useNavigate} from 'react-router-dom';
import classNames from 'classnames';
import {useLocation} from 'react-router';
import {urls} from '../../mocks/urls';
import {CaretDownOutlined, CaretUpFilled, CodeFilled, UserOutlined} from '@ant-design/icons';
import {Dropdown, Menu} from 'antd';
import {CurrentUser} from '../../types/CurrentUser';
import {changeValidateAction} from '../../redux/reducers/auth';
import {ChangeValidateAction} from '../../types/redux/auth';
import {useDispatch} from 'react-redux';

import {Client} from '@stomp/stompjs';
import {Cli} from '../Cli/Cli';
import {Message} from '../../types/Cli/Message';
import {MonitoringNode} from '../../types/Manitoring/MonitoringNode';
import {getMonitoringState} from '../../services/pages/monitoring';
import {CliInteractiveOption} from '../../types/Cli/CliInteractiveOption';


export default function ButtonAppBar() {

    const [isCliOpen, setIsCliOpen] = useState<boolean>(false);
    const [isCliExpand, setIsCliExpand] = useState<boolean>(false);
    const [isStatusOpen, setIsStatusOpen] = useState<boolean>(false);
    const [currentUser, setCurrentUser] = useState<CurrentUser>();
    const [hasAdminRole, setHasAdminRole] = useState<boolean>(false);
    const [cliHeight, setCliHeight] = useState<string>('344px');
    const [interactiveOptions, setInteractiveOptions] = useState<CliInteractiveOption[]>([]);

    const [monitoringNodes, setMonitoringNodes] = useState<MonitoringNode[]>([]);

    const [cliResponse, setCliResponse] = useState<any>();

    const WEBSOCKET_URL = process.env.REACT_APP_WEBSOCKET_API;
    const DOC_URL = process.env.REACT_APP_DOC_API;


    const {pathname} = useLocation();
    const navigate = useNavigate();
    const darkTheme = createTheme({
        palette: {
            mode: 'dark',
            primary: {
                main: '#1976d2',
            },
        },
    });

    const [stompClient, setStompClient] = useState<any>();

    useEffect(() => {
        let cUser = getCurrentUser();
        setCurrentUser(cUser);
        setHasAdminRole(cUser?.roles.indexOf('admin') !== -1);

        getMonitoringState().then((resp) => {
            setMonitoringNodes(resp['nodes']);
        });

        initWebsocketClient();
    }, []);

    useEffect(() => {
        let height = isCliExpand ? 'calc(100vh - 56px )' : '344px';
        setCliHeight(height);
    }, [isCliExpand]);
    
     const initWebsocketClient =()=> {
            let client = new Client();

            client.configure({

                brokerURL: WEBSOCKET_URL,
                debug: function (str) {
                    console.log(str);
                },
                onConnect: () => {
                    console.log('STOMP_CLIENT_ACTIVATE');
                    const destination = `/user/topic/cli`;
                    client && client.subscribe(destination, (message) => {
                        setCliResponse(message);
                    });
                },
                onWebSocketClose: (e) => {
                    console.log('WEBSOCKET_CLOSE');
                    console.log(e);
                },
                onStompError: (e) => {
                    console.log('WEBSOCKET_ERROR');
                    console.log(e);
                }
            });
            setStompClient(client)
            client.activate();

            return () => {
                client && client.deactivate();
            };

    }

    const links = [
        {
            href: "tools",
            title: urls[3].tools
        },
        {
            href: "control",
            title: urls[4].control
        }
    ];

    const statusMenu = (
        <Menu onClick={(event) => onStatusMenuClick(event)}>
            {
                monitoringNodes?.map((node) => {
                    return (
                        <Menu.Item key={node?.node_id + "/" + node?.node_name}>
                            <Status/>{node?.node_name}
                        </Menu.Item>
                    )
                })
            }
            <Menu.Item key="monitoring_all">
                Мониторинг
            </Menu.Item>
        </Menu>
    );

    const profileMenu = (
        <Menu onClick={(event) => onProfileMenuClick(event)}>
            <Menu.Item key="profile">
                {currentUser?.sub}
            </Menu.Item>
            <Menu.Item key="signOut">
                Выход
            </Menu.Item>

            <Menu.Item key="doc">
                Документация
            </Menu.Item>
        </Menu>
    );

    const onStatusMenuClick = (e: any) => {

        if ("monitoring_all" === e.key) {
            doNavigate('/monitoring', navigate);
        } else {
            doNavigate('/monitoring/server/' + e.key, navigate);
        }

    }

    const getProfileUrl =(): string =>{
       return  hasAdminRole ? "/control/profile/" : "/tools/profile/";
    }

    const dispatch = useDispatch();

    const onProfileMenuClick = (e: any) => {
        switch (e.key) {
            case 'profile':
                doNavigate(getProfileUrl() + `${currentUser?.uid}` + '?profile=true', navigate);
                break;
            case 'signOut':
                signOut();
                dispatch(changeValidateAction(false) as ChangeValidateAction);
                doNavigate('/signin', navigate);
                break;
            case 'doc':
                const link = document.createElement('a');
                link.target = '_blank';
                link.rel = 'noopener noreferrer';
                link.href = DOC_URL||'';
                link.click();
                break;
        }
    }

    const onStatusChange = (isOpen: boolean) => {
        setIsStatusOpen(isOpen);
    }

    const onCliClick = () => {
        setIsCliOpen((prevOpen) => !prevOpen);
    }

    const onInputTerminal = (msg: Message) => {

        const destination = `/app/cli`;

        if (stompClient != null) {

            stompClient.publish({
                destination,
                header: { 'Content-Type': 'application/json' },
                body: JSON.stringify(msg),
            });
        }
    };

    const onExpandCli = () => {
        setIsCliExpand((prevExpand) => !prevExpand);
    }

    return (
        <Box sx={{flexGrow: 1}}>
            <ThemeProvider theme={darkTheme}>
                <AppBar id="status_bar" position="static" style={{background: '#23262D', minHeight: '56px'}}>
                    <Toolbar style={{background: '#23262D', minHeight: '56px'}}>

                        <div className={styles.logo}>
                            <Logo/>
                            DAMASK
                        </div>


                        <div style={{position: 'absolute', left: '208px', top: '0'}}>


                            <nav className={styles.navbar}>
                                <ul className={styles.links}>
                                    {links?.map((link) => (


                                        <li key={link.href}
                                            hidden={link.href === "control" && !hasAdminRole}
                                            className={classNames(styles.item, {
                                                [styles.item_active]: link.href === pathname.split("/")[1],
                                            })}>
                                            <div className={classNames(styles.div_item, {
                                                [styles.div_item_active]: link.href === pathname.split("/")[1],
                                            })}/>
                                            <a
                                                id={`icon${link.href}`}
                                                href=""
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    doNavigate(`/${link.href}`, navigate);
                                                    return false;
                                                }}
                                            >
                                                {i18n(link.title)}
                                            </a>
                                        </li>

                                    ))}
                                </ul>
                            </nav>

                        </div>

                        <div className={styles.right_group_button}>
                            <div hidden={!hasAdminRole}>
                                <Status/>
                                <span className={'body_1_long neutral_00'}>Статус</span>
                                <Dropdown
                                    overlayStyle={{width: '220px', zIndex: '16000'}}
                                    placement={'bottomRight'}
                                    getPopupContainer={() => document.getElementById('status_bar')!}
                                    overlay={statusMenu}
                                    onOpenChange={(val) => onStatusChange(val)}
                                    trigger={['click']}>

                                    {isStatusOpen ? <CaretUpFilled style={{fontSize: '12px', cursor: 'pointer'}}/> :
                                        <CaretDownOutlined style={{fontSize: '12px', cursor: 'pointer'}}/>}

                                </Dropdown>
                            </div>
                            <div className={styles.cli_btn}>
                                <CodeFilled style={{marginLeft: '25px'}} className={styles.item_icon_neutral}/>

                                {isCliOpen ? <CaretUpFilled style={{fontSize: '12px', cursor: 'pointer'}} onClick={onCliClick}/> :
                                    <CaretDownOutlined style={{fontSize: '12px', cursor: 'pointer'}} onClick={onCliClick}/>}
                            </div>
                            <div className={styles.circle}>
                                <Dropdown
                                    overlayStyle={{width: '220px', zIndex: '16000'}}
                                    placement={'bottomRight'}
                                    getPopupContainer={() => document.getElementById('status_bar')!}
                                    overlay={profileMenu}
                                    trigger={['click']}>

                                    <UserOutlined className={styles.item_icon_primary_60}/>

                                </Dropdown>

                            </div>

                        </div>
                    </Toolbar>
                </AppBar>

                <div
                    hidden={!isCliOpen}
                    style={{height:cliHeight}}
                    className={styles.cli}>
                    <Cli isOpen={isCliOpen}
                         isExpand={isCliExpand}
                         response={cliResponse}
                         interactiveOptions={interactiveOptions}
                         onCliExpand={onExpandCli}
                         onInputTerminal={onInputTerminal}/>
                </div>
            </ThemeProvider>
        </Box>
    );
}
