import { fetchWithRefresh } from '../auth';
import {
  optionsGet,
  optionsPost,
  optionsPatch,
  optionsDelete,
  URL,
} from '../requst_templates';
import { handleHttpResponse } from '../../utils';


export const getUsers = async (body: object | null = null) => fetchWithRefresh(
  `${URL}/v1/user`,
  optionsGet(),
).then(handleHttpResponse);

export const getUsersPageable = async (limit:number, offset:number) => fetchWithRefresh(
    `${URL}/v1/user?limit=${encodeURIComponent(limit)}&offset=${encodeURIComponent(offset)}`,
    optionsGet(),
).then(handleHttpResponse);

export const getUser = async (userId: string) => fetchWithRefresh(
  `${URL}/v1/user/${encodeURIComponent(userId)}`,
  optionsGet(),
).then(handleHttpResponse);

export const createUser = async (data: any) => fetchWithRefresh(`${URL}/v1/user/`, optionsPost(data)).then(handleHttpResponse);

export const deleteUser = async (userId: string) => fetchWithRefresh(
  `${URL}/v1/user/${encodeURIComponent(userId)}`,
  optionsDelete(),
).then(handleHttpResponse);

export const updateUser = async (userId: string, data: any) => fetchWithRefresh(
  `${URL}/v1/user/${encodeURIComponent(userId)}`,
  optionsPatch(data),
).then(handleHttpResponse);

export const unlockUser = async (userId: string ) => fetchWithRefresh(
    `${URL}/v1/user/unlock/${encodeURIComponent(userId)}`,
    optionsPost(),
).then(handleHttpResponse);

export const createRole = async (userId: string, roleId: string) => fetchWithRefresh(
  `${URL}/v1/user/${encodeURIComponent(userId)}/role/${encodeURIComponent(roleId)}`,
  optionsPost(),
).then(handleHttpResponse);

export const deleteRole = async (userId: string, roleId: string) => fetchWithRefresh(
  `${URL}/v1/user/${encodeURIComponent(userId)}/role/${encodeURIComponent(roleId)}`,
  optionsDelete(),
).then(handleHttpResponse);

export const getAuthUserPing = async (userId: string) => fetchWithRefresh(
    `${URL}/v1/user/${encodeURIComponent(userId)}`,
    optionsGet(),
);
