import styles from './Groups.module.scss';
import React, {useEffect, useState} from 'react';
import {getTokenGroupsPageable} from '../../services/pages/token_group';
import {TGroupResponse} from '../../types/GroupToken/TGroupResponse';
import {NavButton} from '../../components/NavButton/NavButton';
import {CustomerAlert, CustomerAlertProps} from '../../components/CustomerAlert/CustomerAlert';
import {useLocation} from 'react-router';
import {Card, ConfigProvider, Table} from 'antd';
import {useNavigate} from 'react-router-dom';
import {doNavigate, getCurrentUser} from '../../utils';
import InfiniteScroll from 'react-infinite-scroll-component';

import {map} from 'lodash';
import {SearchState} from '../../types/SearchState';
import {TableDropdownFilter} from '../../components/TableDropdownFilter/TableDropdownFilter';
import {FilterItem} from '../../types/FilterItem';
import {TableFilterItem} from '../../types/TableFilterItem';
import {SearchInput} from '../../components/SearchInput/SearchInput';
import {NoFilterData} from '../../components/NoFilterData/NoFilterData';

export function GroupsAll() {


    const [data, setData] = useState<TGroupResponse['items']>([]);
    const [customAlertProps, setAlertProps] = useState<CustomerAlertProps>({severity: '', action: '', show: false});
    const [hasAdminRole, setHasAdminRole] = useState<boolean>(false);

    const {pathname} = useLocation();

    const [isControl, setIsControl] = useState<boolean>(true);

    const [count, setCount] = useState<number>();

    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const [offset, setOffset] = useState<number>(0);
    const [limit, setLimit] = useState<number>(20);

    const [templateFilter, setTemplateFilter] = useState<TableFilterItem[]>( []);
    const [templateFilterItems, setTemplateFilterItems] = useState<FilterItem[]>( []);
    const [templateFiltered, setTemplateFiltered] = useState<string[]| null>( []);

    const [nameFilter, setNameFilter] = useState<TableFilterItem[]>( []);
    const [nameFilterItems, setNameFilterItems] = useState<FilterItem[]>( []);
    const [nameFiltered, setNameFiltered] = useState<string[]| null>( []);


    const [searchState, setSearchState] = useState<SearchState>({
        filteredInfo: null,
        sortedInfo: null,
        filteredData: [],
        filtered: false,
        searchText: ""
    });

    let locale = {
        emptyText: (
            <NoFilterData/>
        )
    };

    const [searchValue, setSearchValue] = useState<string>('');

    const applyTemplateFilter = (filteredValues: string[]) => {
        setTemplateFiltered([...filteredValues]);
    }

    const applyNameFilter = (filteredValues: string[]) => {
        setNameFiltered([...filteredValues]);
    }

    useEffect(() => {
        let parts = pathname.split("/")[1];
        let isControl = parts === 'control';
        setIsControl(isControl)

        let cUser = getCurrentUser();
        setHasAdminRole(cUser?.roles.indexOf('admin') !== -1);
    },[]);

    useEffect(() => {
        loadGroupsData();
    }, []);


    useEffect(()=> {
        initFilter();
    }, [data])

    const loadGroupsData = () => {
        if (loading) {
            return;
        }
        setLoading(true);

        getTokenGroupsPageable(limit, offset).then((response) => {
                let count = response['count']
                setCount(count);
                let groups = response['items'];
                setData((data) => [...data, ...groups]);
                setLoading(false);
                setOffset(offset + limit);

            }
        ).catch((err) => {
            console.log(err);
            setLoading(false);
        });
    }

    const columns = [
        {
            title: 'Имя',
            dataIndex: 'name',
            key: 'group_name',
            width: 240,
            filterDropdown: <TableDropdownFilter
                onApply={applyNameFilter}
                popupId={'scrollableDiv_1'}
                items={nameFilterItems}/>,
            filters: nameFilter,
            filteredValue: nameFiltered,
            onFilter: (value:any, record:any) => record.name.includes(value),
        },
        {
            title: 'Описание',
            dataIndex: 'description',
            key: 'group_description',
            width: 392
        },
        {
            title: 'Шаблон по умолчанию',
            dataIndex: 'default_template_id',
            key: 'group_default_template_id',
            width: 260,
            filterDropdown: <TableDropdownFilter
                onApply={applyTemplateFilter}
                popupId={'scrollableDiv_1'}
                items={templateFilterItems}/>,
            filters: templateFilter,
            filteredValue: templateFiltered,
            onFilter: (value:any, record:any) => record.default_template_id.includes(value),
        }
    ];

    const initFilter=() => {

        let templates = data?.map((group)=> group.default_template_id);
        let names = data?.map((group)=> group.name);

        templates = templates.filter((value, index) => templates.indexOf(value) === index);
        names = names.filter((value, index) => names.indexOf(value) === index);

        const tempFilter = templates.map((val) => ({text: val, value: val}));
        const tempFilterItem = templates.map((val) => ({label: val, value: val}));

        const nameFilter = names.map((val) => ({text: val, value: val}));
        const nameFilterItem = names.map((val) => ({label: val, value: val}));

        setTemplateFilterItems( [  ...tempFilterItem]);
        setTemplateFilter( [  ...tempFilter]);

        setNameFilterItems([...nameFilterItem]);
        setNameFilter([...nameFilter]);
    }

    const alertClose = () => {
        setAlertProps({severity: '', action: '', show: false});
    }

    const handleSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        updateSearchState(event.target.value);
    }

    const clearSearchInput = () => {
      updateSearchState('');
    }

    const updateSearchState = (searchText:string ) => {
        setSearchValue(searchText);
        Object.assign(searchState, {searchText: searchText});

        const filtered = map(data, record => {
            const nameMatch = record.name.includes(searchText);
            const descriptionMatch = record.description.includes(searchText);
            const templateMatch = record.default_template_id.includes(searchText);

            if (!nameMatch && !templateMatch && !descriptionMatch) {
                return null;
            }
            return record;
        }).filter(record => !! record);

        Object.assign(searchState, {filteredData: searchState.searchText? filtered : data});
    }

    const getUrl =(): string => {
        return  hasAdminRole ? "/control/groups/edit/" : "/tools/groups/view/";
    }

    return (
        <ConfigProvider
            theme={{
                components: {
                    Input: {
                        colorPrimary: '#F75623',
                        activeShadow: '0 0 0 1px #F75623',
                        algorithm: true,
                    },
                    Table: {
                        headerBg: '#FFFFFF',
                        colorPrimary:'#F75623',
                        algorithm: true
                    },
                    Select: {
                        colorPrimary: '#F75623',
                        colorErrorBorder:'#D92020',
                        algorithm: true,
                    },
                    Checkbox: {
                        colorPrimary: '#F75623',
                        colorPrimaryHover: '#F75623',
                        algorithm: true,
                    },
                },
            }}
        >
            <Card bordered={false} style={{width: '100%', height: '100%'}} key={'groupsAll'}>
                <div className={styles.page}>


                    <div className={'header_1'}>
                        Группы токенизации
                        <SearchInput searchValue={searchValue}
                                     onChangeHandler={ handleSearchInput}
                                     onClearSearchInput={ clearSearchInput }/>

                    </div>
                    <div className={'header_2'}>

                        {offset < count! ? offset + ' из ' + count : count + ' из ' + count}
                    </div>


                    <div className={styles.form}>
                        <CustomerAlert action={customAlertProps.action}
                                       severity={customAlertProps.severity}
                                       show={customAlertProps.show}
                                       onClose={() => alertClose()}/>


                        <div id="scrollableDiv_1" className={'scrollable_div'} >
                            <InfiniteScroll
                                dataLength={data.length}
                                next={loadGroupsData}
                                hasMore={data.length < count!}
                                scrollThreshold={1}
                                scrollableTarget="scrollableDiv_1"
                                loader={<h6></h6>}
                                style={{overflow:"unset"}}>

                                <Table
                                    key={'all_users_table'}
                                    id="all_users_table"
                                    rowKey={'name'}
                                    dataSource={searchState.searchText ? searchState.filteredData : data}
                                    columns={columns}
                                    locale={locale}
                                    pagination={false}
                                    size="small"
                                    getPopupContainer={()=>document.getElementById('all_users_table')!}
                                    onRow={(record, rowIndex) => {
                                        return {
                                            onClick: () => {
                                                doNavigate(getUrl() + `${record.id}`, navigate);
                                            }, style: {
                                                cursor: 'pointer'
                                            }
                                        };
                                    }}
                                    sticky={true}
                                />
                            </InfiniteScroll>
                        </div>

                    </div>

                </div>
                <div className={styles.nav_btn}>

                    <NavButton route={'/control/groups/create'}
                               btnText={'Добавить группу токенизации'}
                               className={'button_1'}/>
                </div>
            </Card>
        </ConfigProvider>
    );
}
