import React, {FC, useEffect, useState} from 'react';
import {ConfigProvider, Input, Select} from 'antd';
import styles from '../Groups/Groups.module.scss';
import {Template} from '../../types/Template';
import {TGroup} from '../../types/GroupToken/TGroup';

const {TextArea} = Input;


export type CommonGroupInfoProps = {

    className?: string;
    onClickHandler?: () => void;
    templates?: Template[];
    tGroup?: TGroup;
    onInputChange: (event: any) => void;
    onSelectChange: (val: any) => void;
    isValidate: boolean;
    onValidateFinishHandler: (hasError:boolean) => void;
};

export const CommonGroupInfo: FC<CommonGroupInfoProps> = ({
                                                              className,
                                                              onClickHandler,
                                                              templates,
                                                              tGroup,
                                                              onInputChange,
                                                              onSelectChange,
                                                              onValidateFinishHandler,
                                                              isValidate,

                                                          }) => {

    const [errors, setErrors] = useState({
        name: "",
        default_template_id: "",
    });


    useEffect(() => {
        console.log("INSIDE_USE_EFFECT->COMMON");


        if (isValidate) {
            console.log("START_VALIDATE_COMMON_PART");
            const err = validateValues(tGroup);
            console.log(" VALIDATE_COMMON_PART->err");
            console.log(err);

            Object.assign(errors, {name: err.name});
            Object.assign(errors, {default_template_id: err.default_template_id});

            console.log(" VALIDATE_COMMON_PART->errors");
            console.log( errors)
            console.log( hasErrors())

            onValidateFinishHandler(hasErrors());

        }
    }, [isValidate]);

    const validateValues = (tGroup: TGroup | undefined) => {
        let errors = {
            name: "",
            default_template_id: "",

        };
        if (!tGroup?.name) {
            errors.name = "Необходимо заполнить поле";
        }
        if (!tGroup?.default_template_id) {
            errors.default_template_id = "Необходимо выбрать значение";
        }

        console.log("INSIDE_validateValuesFUNCTION->")
        console.log(errors)



        return errors;
    };

    const hasErrors = () => {
        console.log("on_has_erorr")
        let res = false;
        if (errors.name) res = true;
        if (errors.default_template_id) res = true;

        console.log(res);
        return res;
    }


    return (
        <ConfigProvider
            theme={{
                components: {
                    Input: {
                        colorPrimary: '#F75623',
                        activeShadow: '0 0 0 1px #F75623',
                        colorErrorBorder:'#D92020',
                        algorithm: true,
                    },
                    Select: {
                        colorPrimary: '#F75623',
                        colorErrorBorder:'#D92020',

                        algorithm: true,
                    }
                },
            }}>

            <div className={styles.form}>

                <div className={styles.form_left}>

                    <div>
                        <div style={{marginBottom: '4px'}}>
                            Название
                            <span className={styles.required}>
                            *
                        </span>
                        </div>
                        <Input name="name"
                               value={tGroup?.name}
                               onChange={(e) => onInputChange(e)}/>
                        {errors.name ? (
                            <p className={styles.error}> {errors.name} </p>
                        ) : null}
                    </div>

                    <div style={{marginTop: '24px'}}>

                        <div style={{marginBottom: '4px'}}>
                            Шаблон по умолчанию
                            <span className={styles.required}>
                            *
                        </span>
                        </div>


                        <Select style={{width: '100%'}}
                                value={tGroup?.default_template_id}
                                getPopupContainer={triggerNode => triggerNode.parentNode}
                                placeholder={'Выберите значение'}
                                onChange={(val) => onSelectChange(val)}
                                options={templates?.map((template) => ({label: template.id, value: template.id}))}
                        />
                        {errors.default_template_id ? (
                            <p className={styles.error}> {errors.default_template_id} </p>
                        ) : null}

                    </div>
                </div>

                <div className={styles.form_right}>

                    <div style={{marginBottom: '4px'}}>
                        Описание

                    </div>
                    <TextArea style={{height: "108px"}}
                              value={tGroup?.description}
                              name="description"
                              spellCheck={false}
                              onChange={(e) => onInputChange(e)}/>
                </div>
            </div>

        </ConfigProvider>
    )
}
