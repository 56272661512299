import React, {FC} from 'react';
import {uuid} from '../../utils';

export type CliJsonOutputProps = {
    jsonData?: any;

};

export const CliJsonListOutput: FC<CliJsonOutputProps> = ({

                                                              jsonData
                                                          }) => {


    return (
        <span className={'body_1_long'} key={uuid()}>
            <table className="body_1_long">
                <thead>
                <tr key={uuid()}>
                    {jsonData ?
                        Object.keys(jsonData[0]).map((key, i) => {
                            return (

                                <th key={uuid()} style={i === 0 ? {paddingLeft: '0px'} : {paddingLeft: '10px'}}> {key.toUpperCase()} </th>
                            )
                        })
                        : <>
                        </>
                    }
                </tr>
                </thead>
                <tbody>
                {jsonData?.map(
                    (info: any) => {
                        return (
                            <tr key={uuid()}>
                                {Object.keys(info).map((key, i) => {
                                    return (
                                        <td key={uuid()}
                                            style={i === 0 ? {paddingLeft: '0px'} : {paddingLeft: '10px'}}> {info[key as keyof typeof info]} </td>
                                    )

                                })}
                            </tr>
                        )
                    }
                )}
                </tbody>
            </table>
        </span>
    )
}
